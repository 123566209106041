<template>
  <div>

    <div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" class="navbar-component _2 w-nav">
      <div class="navbar-container w-container">
        <a @click="goToHome" class="navbar-logo-link no-rizer w-nav-brand"><img src="../../../../../public/images/brokton.svg" loading="lazy" alt="" class="navbar-logo"></a>
      </div>
    </div>
    <div class="section color mid-line">
      <div class="container last form one w-container">
        <div class="form-regidet w-form">
          <form id="wf-form-signup-2" name="wf-form-signup-2" data-name="signup" method="get" data-ms-form="signup" class="form-input-regidet">
            <v-form ref="form_reg1" lazy-validation>
              <div class="line-progress"></div>
              <div class="tittle-form">
                <h1 class="heading-form" style="margin-bottom: 10px;">Кредитный рейтинг</h1>
              </div>

              <!-- ТУТ НАЧИНАЮТСЯ ВОПРОСЫ -->
              <div v-for="question in questions" :key="question.id">
                <div class="tittle-form">
                  <h1 class="heading-form is-small">{{ question.text }}</h1>
                </div>
                <div class="w-layout-vflex flex-form">
                  <div class="w-layout-hflex flex-input">
                    <div class="input-box check block">
                      <label
                          v-for="answer in question.answers"
                          :key="answer.id"
                          class="radio-button-field w-radio"
                          @click.prevent="question.answer = answer.value"
                      >
                        <div
                            :class="{ 'w--redirected-checked': question.answer === answer.value }"
                            class="w-form-formradioinput w-form-formradioinput--inputType-custom radio-button w-radio-input"
                        ></div>
                        <input
                            type="radio"
                            data-name="Radio 3"
                            id="radio-3"
                            name="radio"
                            value="true"
                            style="opacity: 0; position: absolute; z-index: -1"
                        />
                        <span class="text-classic top w-form-label" for="radio-3">{{ answer.text }}</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <!-- ТУТ ЗАКАНЧИВАЮТСЯ ВОПРОСЫ -->


              <a
                  style="margin-top: 15px;"
                  aria-current="page"
                  @click.prevent="sendForm"
                  class="button-bust w-button w--current">
                Отправить
              </a>
              <link rel="prerender">
            </v-form>
          </form>
        </div>
      </div>
    </div>

    <Footer />

  </div>
</template>

<script>
import { Footer } from '/src/app/shared/components';
import loadjs from 'loadjs';

export default {
  name: 'RegistrationStep1',
  data() {
    return {
      selectedGender: false,
    };
  },
  components: {
    Footer
  },
  computed: {
    questions() {
      return this.$store.getters.QUESTIONS;
    },
    rate_index() {
      return this.$store.getters.RATE_INDEX;
    },
  },
  created() {
  },
  methods: {
    goToHome() {
      this.$router.push('/');
    },
    sendForm() {
      let hasUnanswered = this.questions.some(question => question.answer === null);

      if (hasUnanswered) {
        this.$buefy.toast.open({
          message: 'Ответьте на все вопросы!',
          type: 'is-danger',
        });
      } else {
        let sum = this.sumAnswers();
        let result = this.rate_index * sum;

        this.$store.dispatch('UPDATE_CREDIT_RATING', result).then(() => {
          // обнуляем ответы
          this.questions.forEach(question => question.answer = null);

          this.$router.push('/personal-data');
        }).catch(() => {
          this.$buefy.toast.open({
            message: 'Произошла ошибка. Попробуйте позже',
            type: 'is-danger',
          });
        });
      }
    },
    sumAnswers() {
      return this.questions.reduce((total, question) => {
        return total + (question.answer || 0);
      }, 0);
    }
  },
  mounted() {
    /* грузим jquery и webflow */
    loadjs(
        "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=652921c910bae02d8870dddf",
        {
          success: () => {
            loadjs('./js/webflow.js', {
              success: () => {
                console.log('Webflow loaded');
              },
              error: () => {
                console.error('Failed to load Webflow');
              }
            });
          },
          error: () => {
            console.error('Failed to load jQuery');
          }
        }
    );
  },
};
</script>

<style scoped>

.container.last.form.one {
  padding-top: 38px;
}

.heading-form.is-small {
  font-size: 18px;
  margin-bottom: 15px;
  margin-top: 25px;
}

.input-box.check.block {
  display: block;
  flex: none;
}

.input-box.check.block .radio-button-field {
  justify-content: normal;
  padding-bottom: 8px;
}

</style>
